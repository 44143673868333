import React from 'react'
import { ActionButtonSkeleton, MoreBar, useToggle } from '@revolut/ui-kit'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import { useScorecardsValidation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScorecardsValidation'
import { ValidateScorecardsPopup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/Popup'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
}

export const ReviewActions = ({ cycle, eligibleGroups }: Props) => {
  const { toPerformanceSummary } = useNavigation()
  const [isValidationPopupOpen, validationPopupToggler] = useToggle()
  const { isValid, isLoading } = useScorecardsValidation(cycle.id)

  if (isLoading) {
    return <ActionButtonSkeleton width={200} />
  }

  return (
    <MoreBar>
      {!isValid && (
        <MoreBar.Action useIcon="DocumentsPair" onClick={validationPopupToggler.on}>
          Validate scorecards
        </MoreBar.Action>
      )}

      <MoreBar.Action useIcon="16/ListBullet" onClick={toPerformanceSummary(cycle)}>
        Performance summary
      </MoreBar.Action>
      <ValidateScorecardsPopup
        cycle={cycle}
        eligibleGroups={eligibleGroups}
        isOpen={isValidationPopupOpen}
        handleClose={validationPopupToggler.off}
      />
    </MoreBar>
  )
}
