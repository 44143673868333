import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'

type NavigationFunction = (
  cycle: ReviewCyclesInterface,
  params?: Record<string, unknown>,
) => () => void

interface Navigation {
  toPerformanceSummary: NavigationFunction
  toPerformanceDepartmentGoals: NavigationFunction
  toPerformanceTeamGoals: NavigationFunction
  toDepartmentGoals: NavigationFunction
  toTeamGoals: NavigationFunction
  toEditEligibleEmployees: NavigationFunction
}

const performanceSummaryPath = ROUTES.PERFORMANCE.REVIEWS_CALIBRATION
const performanceGoalsPath = ROUTES.PERFORMANCE.GOALS.GENERAL
const departmentGoalsPath = ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL
const teamGoalsPath = ROUTES.FORMS.TEAM.GOALS.GENERAL
const editEligibleEmployeesPath = ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES

const selectorsStaleTimeMS = 1000 * 60 * 3

export const useNavigation = (): Navigation => {
  const { data: goalOwnersTypes = [] } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    'v1',
    { staleTime: selectorsStaleTimeMS },
  )

  const toPerformanceSummary: NavigationFunction =
    ({ offset }) =>
    () => {
      navigateTo(
        pathToUrl(
          performanceSummaryPath,
          {},
          offset !== undefined ? { cycle__offset: offset.toString() } : {},
        ),
      )
    }

  const toPerformanceDepartmentGoals: NavigationFunction =
    ({ id }) =>
    () => {
      const goalOwnerIdParam = goalOwnersTypes.find(
        ({ model }) => model === 'department',
      )?.id
      const queryParams = {
        cycle__id: String(id),
        ...(goalOwnerIdParam ? { content_type_id: goalOwnerIdParam.toString() } : {}),
      }

      navigateTo(pathToUrl(performanceGoalsPath, {}, queryParams))
    }

  const toPerformanceTeamGoals: NavigationFunction =
    ({ id }) =>
    () => {
      const goalOwnerIdParam = goalOwnersTypes.find(({ model }) => model === 'teams')?.id
      const queryParams = {
        cycle__id: String(id),
        ...(goalOwnerIdParam ? { content_type_id: goalOwnerIdParam.toString() } : {}),
      }

      navigateTo(pathToUrl(performanceGoalsPath, {}, queryParams))
    }

  const toDepartmentGoals: NavigationFunction =
    ({ id }, params) =>
    () => {
      navigateTo(pathToUrl(departmentGoalsPath, params ?? {}, { cycle__id: String(id) }))
    }

  const toTeamGoals: NavigationFunction =
    ({ id }, params) =>
    () => {
      navigateTo(pathToUrl(teamGoalsPath, params ?? {}, { cycle__id: String(id) }))
    }

  const toEditEligibleEmployees: NavigationFunction =
    ({ id }) =>
    () =>
      navigateTo(pathToUrl(editEligibleEmployeesPath, { id }))

  return {
    toPerformanceSummary,
    toPerformanceDepartmentGoals,
    toPerformanceTeamGoals,
    toDepartmentGoals,
    toTeamGoals,
    toEditEligibleEmployees,
  }
}
