import {
  BottomSheet,
  Button,
  Group,
  Icon,
  IconName,
  Item,
  Placeholder,
} from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { useCreateTestCycle } from '@src/api/reviewCycles'
import { ROUTES } from '@src/constants/routes'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  cycle: ReviewCyclesInterface
}

const CONTENT: { icon: IconName; title: string; description: string }[] = [
  {
    icon: 'Time',
    title: '1. Cycle stages',
    description: 'Our review cycles have 6 stages - we recommend to include all of them',
  },
  {
    icon: 'Document',
    title: '2. Score cards',
    description: 'Who should review whom?',
  },
  {
    icon: 'Profile',
    title: '3. Eligible employees',
    description: 'Who should participate in this run?',
  },
  {
    icon: 'IndustrialGear',
    title: '4. Set calibration logic',
    description: 'How to calculate the final grade?',
  },
]

export const CreateTestCyclePopup = ({ isOpen, onClose, cycle }: Props) => {
  const { mutateAsync: createTestCycle, isLoading } = useCreateTestCycle()
  const showStatusPopup = useShowStatusPopup()

  const handleCreate = async () => {
    try {
      const resp = await createTestCycle({ parent_cycle_id: cycle.id })
      showStatusPopup({
        onClose: () => {
          onClose()
          navigateReplace(
            pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, { id: resp.data.id }),
          )
        },
        title: 'Test cycle started',
      })
    } catch (err) {
      showStatusPopup({
        status: 'error',
        title: 'Test cycle not created',
        description: getStringMessageFromError(err, 'Please try again later'),
      })
    }
  }

  return (
    <BottomSheet open={isOpen} onClose={onClose}>
      <Placeholder>
        <Placeholder.Image
          size={88}
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D332.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D332@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D332@3x.png',
          }}
        />
        <Placeholder.Title>
          <FormattedMessage
            values={{ cycleName: cycle.name }}
            defaultMessage="Test your {cycleName} review cycle"
            id="performance.reviews.cycles.testCycle.title"
          />
        </Placeholder.Title>
        <Placeholder.Description>
          <FormattedMessage
            defaultMessage="We pre-filled the following setup for you"
            id="performance.reviews.cycles.testCycle.description"
          />
        </Placeholder.Description>
      </Placeholder>
      <Group>
        {CONTENT.map((item, index) => (
          <Item key={index}>
            <Item.Avatar>
              <Icon name={item.icon} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{item.title}</Item.Title>
              <Item.Description>{item.description}</Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Group>
      <BottomSheet.Actions gradient="full">
        <Button variant="primary" pending={isLoading} onClick={handleCreate}>
          Start test run
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
