import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import {
  Avatar,
  Box,
  DetailsCell,
  Flex,
  Group,
  Header,
  Layout,
  Link,
  MoreBar,
  Side,
  Tag,
  Tooltip,
  Token,
  useTooltip,
} from '@revolut/ui-kit'
import {
  Archive as ArchiveIcon,
  BarChart,
  LinkExternal,
  ListBullet,
  LockOpened,
  Pencil,
  StarFilled,
} from '@revolut/icons'
import {
  activateDashboard,
  archiveDashboard,
  deleteRelatedCompany,
  deleteRelatedDepartments,
  deleteRelatedTeams,
  deleteRelatedEmployees,
  deleteRelatedFunctions,
  deleteRelatedRoles,
  deleteRelatedSpecialisations,
  useUpdateLookerDashboard,
} from '@src/api/analyticsDashboards'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import {
  AnalyticsDashboardInterface,
  GenericAnalyticsDashboardInterface,
} from '@src/interfaces/analyticsDashboards'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import { DeleteButton } from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { EntityPermissions, FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { RateDashboardPopup } from './RateDashboardPopup'
import { RequestAccessPopup } from './RequestAccessPopup'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { getEmbedUrlWithLoginScreen } from '../GoalForm/Form/GoalMetricForm/helpers'

const BACK_URL = ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS
const PAGE_PADDING_BOTTOM = 16
const WRAPPER_MARGIN = 16
const HEADER_HEIGHT = 60
const MORE_BAR_HEIGHT = 48

const mapDashboardTypeLink: Record<
  GenericAnalyticsDashboardInterface['dashboard_type'],
  string
> = {
  looker: ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS,
  tableau: ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.DETAILS,
  internal: ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.DETAILS,
}

export const DashboardForm = connect(() => {
  const featureFlags = useSelector(selectFeatureFlags)
  const tooltip = useTooltip()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { reset, values } = useLapeContext<AnalyticsDashboardInterface>()
  const [accessPopupOpen, setAccessPopupOpen] = useState(false)
  const [archivePending, setArchivePending] = useState(false)
  const [ratePopupOpen, setRatePopupOpen] = useState(false)
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [wrapperHeight, setWrapperHeight] = useState(0)

  const canRequestAccesses = !featureFlags.includes(FeatureFlags.CommercialProduct)
  const needToProvideAccesses = !values.folder && !values.lookml_models?.length
  const canChange = !!values.field_options?.actions?.includes(EntityPermissions.Change)
  const canDelete =
    !!values.related_teams.length ||
    !!values.related_departments.length ||
    !!values.related_employees.length ||
    values.company_related

  useEffect(() => {
    if (wrapperRef.current) {
      const windowHeight = window.innerHeight - PAGE_PADDING_BOTTOM
      const wrapperRect = wrapperRef.current.getBoundingClientRect()
      setWrapperHeight(
        windowHeight - wrapperRect.top + HEADER_HEIGHT + MORE_BAR_HEIGHT + WRAPPER_MARGIN,
      )
    }
  }, [])

  const handleArchive = (action: Statuses.active | Statuses.archived) => {
    setArchivePending(true)

    const callback = action === Statuses.active ? archiveDashboard : activateDashboard

    callback(values.id)
      .then(response => reset({ ...values, status: response.data.status }))
      .finally(() => setArchivePending(false))
  }

  const handleDelete = () => {
    if (values.related_teams.length) {
      return deleteRelatedTeams(values.id, values.related_teams[0].id)
    }
    if (values.related_departments.length) {
      return deleteRelatedDepartments(values.id, values.related_departments[0].id)
    }
    if (values.related_employees.length) {
      return deleteRelatedEmployees(values.id, values.related_employees[0].id)
    }
    if (values.related_roles?.length) {
      return deleteRelatedRoles(values.id, values.related_roles[0].id)
    }
    if (values.related_functions?.length) {
      return deleteRelatedFunctions(values.id, values.related_functions[0].id)
    }
    if (values.related_specialisations?.length) {
      return deleteRelatedSpecialisations(values.id, values.related_specialisations[0].id)
    }
    return deleteRelatedCompany(values.id)
  }

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS}
        title={
          <PageHeader.Title
            title={values.name}
            avatar={
              <EntityAvatar<AnalyticsDashboardInterface>
                data={values}
                defaultIcon="BarChart"
                api={useUpdateLookerDashboard}
                canEdit={canChange}
                onSuccess={data => {
                  if (data) {
                    reset(data)
                  }
                }}
              />
            }
            actions={
              <MoreBar>
                <MoreBar.Action onClick={() => setSideBarOpen(true)} useIcon={ListBullet}>
                  Show details
                </MoreBar.Action>
                <MoreBar.Action
                  href={values.content_url}
                  target="_blank"
                  use={Link}
                  useIcon={LinkExternal}
                >
                  Go to external dashboard
                </MoreBar.Action>
                {canRequestAccesses && (
                  <MoreBar.Action
                    aria-disabled={needToProvideAccesses}
                    onClick={() => setAccessPopupOpen(!needToProvideAccesses)}
                    useIcon={LockOpened}
                    {...tooltip.getAnchorProps()}
                  >
                    Request necessary accesses
                    {needToProvideAccesses && (
                      <Tooltip {...tooltip.getTargetProps()} maxWidth={450}>
                        The owner has not specified the Looker models and folders required
                        to get access to this dashboard. Please contact the owner and ask
                        to update the dashboard details to enable this functionality.
                      </Tooltip>
                    )}
                  </MoreBar.Action>
                )}
                {canChange && (
                  <MoreBar.Action
                    to={pathToUrl(mapDashboardTypeLink[values.dashboard_type], {
                      id: values.id,
                    })}
                    use={InternalLink}
                    useIcon={Pencil}
                  >
                    Edit dashboard
                  </MoreBar.Action>
                )}
                <MoreBar.Action
                  onClick={() => setRatePopupOpen(true)}
                  useIcon={StarFilled}
                >
                  Rate dashboard
                </MoreBar.Action>
                {canDelete && (
                  <DeleteButton
                    backUrl={BACK_URL}
                    deleteApi={handleDelete}
                    dialogProps={{
                      body: `Are you sure you want to delete ${values.name} dashboard?`,
                    }}
                    entityPermissions={EntityPermissions.Change}
                    label="Remove"
                    title="dashboard"
                  />
                )}
                {canChange && (
                  <MoreBar.Action
                    onClick={() => handleArchive(values.status.id)}
                    pending={archivePending}
                    useIcon={ArchiveIcon}
                    variant="negative"
                  >
                    {values.status.id === Statuses.active ? 'Archive' : 'Unarchive'}
                  </MoreBar.Action>
                )}
              </MoreBar>
            }
          />
        }
      />
      <PageBody maxWidth={Token.breakpoint.xxl} mb={0}>
        <Box
          border={`1px solid ${Token.color.greyTone50}`}
          borderRadius="r16"
          overflow="hidden"
          height={wrapperHeight}
          mt="s-16"
          ref={wrapperRef}
          width="100%"
        >
          {values.dashboard_type === 'looker' && (
            <iframe
              data-testid="looker-iframe"
              height="100%"
              src={getEmbedUrlWithLoginScreen(values.embed_url)}
              style={{
                border: 0,
                borderRadius: '16px',
              }}
              title="Dashboard"
              width="100%"
            />
          )}
          {values.dashboard_type === 'tableau' && (
            <tableau-viz
              id="tableauViz"
              src={values.content_url}
              toolbar="bottom"
              hide-tabs
              height="100%"
            />
          )}
        </Box>
        <Layout.SideFill>
          <Side onClose={() => setSideBarOpen(false)} open={sideBarOpen}>
            <Header variant="item">
              <Header.CloseButton
                aria-label="Close sidebar"
                onClick={() => setSideBarOpen(false)}
              />
              <Header.Title>Dashboard details</Header.Title>
              <Header.Avatar>
                <Avatar size={40} useIcon={BarChart} />
              </Header.Avatar>
            </Header>
            <FormPreview data={values} title="">
              <Group>
                <FormPreview.Item field="name" title="Name" />
                <FormPreview.Details field="description" title="Description" />
                <FormPreview.Item field="owner" title="Owner" type="employee" />
                <FormPreview.Context<AnalyticsDashboardInterface>
                  insert={data => (
                    <>
                      <DetailsCell>
                        <DetailsCell.Title>Linked Metrics</DetailsCell.Title>
                        {!data.related_kpis.length && (
                          <DetailsCell.Content>-</DetailsCell.Content>
                        )}
                      </DetailsCell>
                      {!!data.related_kpis.length && (
                        <Flex flexWrap="wrap" gap="s-4" px="s-16">
                          {data.related_kpis.map(item => (
                            <Tag key={item.id} variant="faded">
                              {item.name}
                            </Tag>
                          ))}
                        </Flex>
                      )}
                    </>
                  )}
                />
                <FormPreview.Item
                  field="dashboard_type"
                  title="Type"
                  type="capitalized"
                />
                <FormPreview.Item
                  field="content_url"
                  title="Link to external"
                  type="link"
                />
                <FormPreview.Item
                  field="creation_date_time"
                  title="Created on"
                  type="dateTime"
                />
                <FormPreview.Item
                  field="update_date_time"
                  title="Last updated on"
                  type="dateTime"
                />
              </Group>
            </FormPreview>
          </Side>
        </Layout.SideFill>
        <RateDashboardPopup
          id={values.id}
          isOpen={ratePopupOpen}
          onClose={() => setRatePopupOpen(false)}
        />
        <RequestAccessPopup
          isOpen={accessPopupOpen}
          onClose={() => setAccessPopupOpen(false)}
        />
      </PageBody>
    </PageWrapper>
  )
})
