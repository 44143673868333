import React from 'react'
import { Avatar, Box, Grid, MoreBar, Text, Token, useToggle } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { CreateTestCyclePopup } from '../CreateTestCyclePopup'
import { cycleModel } from '../../models/CycleModel'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CommonActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { toPerformanceSummary } = useNavigation()
  const [isCreateTestCycleOpened, setIsCreateTestCycleToggle] = useToggle()

  const handlePreviewScorecardClick = () =>
    navigateTo(pathToUrl(ROUTES.PERFORMANCE.SCORECARD_PREVIEW, { id }))

  return (
    <>
      <MoreBar.Action useIcon="EyeShow" onClick={handlePreviewScorecardClick}>
        Preview scorecard
      </MoreBar.Action>

      {!cycleModel.isTest(cycle) && (
        <MoreBar.Action onClick={setIsCreateTestCycleToggle.on} useIcon="Plus">
          <Tooltip
            backgroundColor={Token.color.widgetBackground}
            placement="bottom"
            noArrow
            body={
              <Grid
                p="s-16"
                columns="auto 1fr"
                rows="repeat(2, auto)"
                columnGap="s-16"
                width="max-content"
                maxWidth={340}
              >
                <Box style={{ gridRow: '1/3' }}>
                  <Avatar useIcon="Lightbulb" />
                </Box>
                <Text variant="emphasis1" color={Token.color.foreground}>
                  Test your review cycle
                </Text>
                <Text variant="body2" color={Token.color.greyTone50}>
                  Simulate a review cycle based on your configuration, and fine-tune your
                  process before going live
                </Text>
              </Grid>
            }
          >
            Create test cycle
          </Tooltip>
        </MoreBar.Action>
      )}
      <MoreBar.Action useIcon="16/ListBullet" onClick={toPerformanceSummary(cycle)}>
        Performance summary
      </MoreBar.Action>
      <CreateTestCyclePopup
        isOpen={isCreateTestCycleOpened}
        cycle={cycle}
        onClose={setIsCreateTestCycleToggle.off}
      />
    </>
  )
}
