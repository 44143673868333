import React from 'react'
import { ActionButton, Avatar, Box, Details, Group, Item, Widget } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatPeriod } from '@src/utils/format'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CycleSettings = ({ cycle }: Props) => {
  const { id, name } = cycle

  const isTest = cycleModel.isTest(cycle)
  const hasAutoScorecardsGeneration = cycleModel.hasAutoScorecardsGeneration(cycle)
  const startDate = cycleModel.getStartDate(cycle)
  const endDate = cycleModel.getEndDate(cycle)

  const editSettingsPath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL_SETTINGS, { id })

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="IndustrialGear" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Cycle Settings</Item.Title>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <ActionButton to={editSettingsPath} use={InternalLink}>
              Edit
            </ActionButton>
          </Item.Value>
        </Item.Side>
      </Item>
      <Box px="s-16">
        <Group>
          {!isTest && (
            <>
              <Details>
                <Details.Title>Cycle name</Details.Title>
                <Details.Content>{name}</Details.Content>
              </Details>
              <Details>
                <Details.Title>Cycle timeframe</Details.Title>
                <Details.Content>{formatPeriod(startDate, endDate)}</Details.Content>
              </Details>
            </>
          )}
          <Details>
            <Details.Title>Auto scorecard generation</Details.Title>
            <Details.Content>
              {hasAutoScorecardsGeneration ? 'Enabled' : 'Disabled'}
            </Details.Content>
          </Details>
        </Group>
      </Box>
    </Widget>
  )
}
