import React from 'react'
import { notReachable } from '@src/utils/notReachable'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { ReviewActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/ReviewActions'
import { CalibrationActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/CalibrationActions'
import { GoalsActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/GoalsActions'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  stage: ReviewCycleStage
}

export const StageActions = ({ cycle, eligibleGroups, stage }: Props) => {
  // TODO: leaving case branches for following https://revolut.atlassian.net/browse/REVC-6853
  //  subtasks: each stage will have it's own additional actions
  switch (stage) {
    case ReviewCycleStage.Review:
      return <ReviewActions cycle={cycle} eligibleGroups={eligibleGroups} />
    case ReviewCycleStage.Calibration:
      return <CalibrationActions cycle={cycle} />
    case ReviewCycleStage.TeamGoals:
    case ReviewCycleStage.DepartmentGoals:
      return <GoalsActions />
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
    case ReviewCycleStage.Completed:
      return null
    default:
      return notReachable(stage)
  }
}
