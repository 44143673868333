import React from 'react'
import { connect } from 'lape'
import { InputGroup, TextWidget, VStack } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import {
  cycleNameDescription,
  enableScorecardsSwitcherProps,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSettings/EditCycleSettings/constants'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

export const EditFormContent = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()

  const isTest = cycleModel.isTest(values)

  return (
    <VStack space="s-16">
      {!isTest && (
        <TextWidget>
          <TextWidget.Title>General settings</TextWidget.Title>
          <TextWidget.Content>
            <InputGroup>
              <LapeNewInput
                name="name"
                label="Cycle name"
                description={cycleNameDescription}
                required
              />
              <InputGroup variant="horizontal">
                <LapeDatePickerInput
                  name="start_date_time"
                  label="Cycle Start Date"
                  required
                />
                <LapeDatePickerInput
                  name="end_date_time"
                  label="Cycle End Date"
                  required
                />
              </InputGroup>
            </InputGroup>
          </TextWidget.Content>
        </TextWidget>
      )}
      <TextWidget>
        <LapeNewSwitch
          name="enabled_scorecards_generations"
          itemTypeProps={enableScorecardsSwitcherProps}
        />
      </TextWidget>
    </VStack>
  )
})
