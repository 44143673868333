import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { Virtuoso } from 'react-virtuoso'
import React, { RefObject, useEffect, useState } from 'react'
import { PopupState } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/constants'
import { notReachable } from '@src/utils/notReachable'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import { useInvalidEligibilityObjects } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/hooks/useInvalidEligibilityObjects'
import {
  InvalidEligibilityItemsSkeleton,
  EligibleGroupItemContext,
  InvalidEligibilityItemProps,
  renderEligibilityGroupItem,
  renderInvalidEligibilityItem,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/ListItems'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  state: PopupState
  scrollRef?: RefObject<HTMLDivElement>
}

export const EligibilityList = ({ cycle, eligibleGroups, state, scrollRef }: Props) => {
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | undefined>(undefined)
  const { toEditEligibleEmployees } = useNavigation()

  useEffect(() => {
    if (scrollRef?.current && !scrollParent) {
      setScrollParent(scrollRef.current)
    }
  }, [scrollRef?.current])

  switch (state) {
    case PopupState.Default:
      return (
        <DefaultState
          eligibleGroups={eligibleGroups}
          scrollParent={scrollParent}
          onItemActionClick={() => toEditEligibleEmployees(cycle)()}
        />
      )
    case PopupState.Valid:
      return <ValidState eligibleGroups={eligibleGroups} scrollParent={scrollParent} />
    case PopupState.Invalid:
      return <InvalidState cycleId={cycle.id} scrollParent={scrollParent} />

    default:
      return notReachable(state)
  }
}

interface ListStateProps {
  scrollParent?: HTMLDivElement
}

interface DefaultStateProps extends ListStateProps {
  eligibleGroups: EligibleGroupInterface[]
  onItemActionClick: VoidFunction
}

const DefaultState = ({
  eligibleGroups,
  scrollParent,
  onItemActionClick,
}: DefaultStateProps) => (
  <Virtuoso<EligibleGroupInterface, EligibleGroupItemContext>
    overscan={10}
    height={500}
    data={eligibleGroups}
    itemContent={(index, props) =>
      renderEligibilityGroupItem(index, props, {
        onItemActionClick,
      })
    }
    customScrollParent={scrollParent}
  />
)

interface ValidStateProps extends ListStateProps {
  eligibleGroups: EligibleGroupInterface[]
}

const ValidState = ({ eligibleGroups, scrollParent }: ValidStateProps) => (
  <Virtuoso<EligibleGroupInterface, EligibleGroupItemContext>
    overscan={10}
    height={500}
    data={eligibleGroups}
    itemContent={(index, props) =>
      renderEligibilityGroupItem(index, props, { isValid: true })
    }
    customScrollParent={scrollParent}
  />
)

interface InvalidStateProps extends ListStateProps {
  cycleId: number | string
}

export const InvalidState = ({ cycleId, scrollParent }: InvalidStateProps) => {
  const { itemProps, isLoading, handleFetchMore } = useInvalidEligibilityObjects(cycleId)

  if (isLoading) {
    return <InvalidEligibilityItemsSkeleton />
  }

  return (
    <Virtuoso<InvalidEligibilityItemProps>
      overscan={10}
      height={500}
      data={itemProps}
      itemContent={(index, props) => renderInvalidEligibilityItem(index, props)}
      customScrollParent={scrollParent}
      endReached={handleFetchMore}
    />
  )
}
