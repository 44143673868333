import React from 'react'
import { Flex, Text } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import { getFunctionNIPSColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatNumber } from '@src/utils/format'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { BarChart } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PerformanceTimeRange } from '@src/constants/api'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { AxiosPromise } from 'axios'
import { performanceClarification } from '@src/pages/Forms/CommonTalentTab/utils'

type Props = {
  id: number
  nips: number
  nipsGraphRequest: (
    id: number | string,
    range?: PerformanceTimeRange,
    cycleOffset?: string,
  ) => AxiosPromise<PerformanceChartCycles>
  label?: string
  rightMargin?: boolean
}

const TalentHeaderNIPS = ({
  id,
  nipsGraphRequest,
  nips,
  label = 'NIPS',
  rightMargin = true,
}: Props) => {
  return (
    <Stat
      label={label}
      val={
        <Flex alignItems="center">
          <Tooltip
            text={`Net Individual Performance Score (NIPS) = %Exceptional + %Exceeding + %Performing - %Developing - %Unsatisfactory. ${performanceClarification}`}
            placement="right"
          >
            <Text use="div" mr="5px" color={getFunctionNIPSColor(nips || 0)}>
              {`${formatNumber(nips, 1)}%`}
            </Text>
          </Tooltip>
          {nipsGraphRequest && (
            <GraphIconChart id={id} vertical="right" fetchData={nipsGraphRequest}>
              <Flex pr="s-12">
                <BarChart cursor="pointer" size={16} color="grey-tone-20" />
              </Flex>
            </GraphIconChart>
          )}
        </Flex>
      }
      mr={rightMargin ? 's-32' : 0}
    />
  )
}

export default TalentHeaderNIPS
