import React from 'react'
import pluralize from 'pluralize'
import {
  Avatar,
  Columns,
  IconButton,
  Item,
  ItemSkeleton,
  Text,
  Token,
} from '@revolut/ui-kit'
import { ChevronRight, Services } from '@revolut/icons'

import { DocumentsCategoryInterface } from '@src/interfaces/documents'

const ColumnsWrapper: React.FC = ({ children, ...props }) => {
  return (
    <Columns gap="s-16" calc={rect => rect.width / 410} {...props}>
      {children}
    </Columns>
  )
}

type Props = {
  isLoading: boolean
  categories: DocumentsCategoryInterface[] | undefined
  emptyState: React.ReactNode
  onClickCategory: (categoryId: number) => void
}
export const FoldersByCategories = ({
  isLoading,
  categories,
  emptyState,
  onClickCategory,
}: Props) => {
  if (isLoading) {
    const loader = (
      <ItemSkeleton>
        <ItemSkeleton.Avatar />
        <ItemSkeleton.Content />
      </ItemSkeleton>
    )
    return (
      <ColumnsWrapper>
        {loader}
        {loader}
        {loader}
        {loader}
      </ColumnsWrapper>
    )
  }
  if (!categories?.length) {
    return <>{emptyState}</>
  }

  return (
    <ColumnsWrapper>
      {categories.map(category => (
        <Item
          key={category.category_id}
          use="button"
          onClick={() => onClickCategory(category.category_id)}
          style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
        >
          <Item.Avatar>
            <Avatar useIcon={Services} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{category.category_name || 'Unknown category'}</Item.Title>
            <Item.Description>
              {category.documents_pending ? (
                <Text color={Token.color.warning}>
                  {category.documents_pending} Pending
                </Text>
              ) : (
                <Text>{pluralize('Document', category.documents_count, true)}</Text>
              )}
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <IconButton useIcon={ChevronRight} color={Token.color.greyTone50} />
          </Item.Side>
        </Item>
      ))}
    </ColumnsWrapper>
  )
}
