import React, { useCallback, useState } from 'react'
import { Drag, Pencil } from '@revolut/icons'
import {
  Box,
  Button,
  DragAndDrop,
  Flex,
  IconButton,
  Item,
  Side,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { move } from '@src/utils/move'
import SideBar from '@components/SideBar/SideBar'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { LocalStorageKeys } from '@src/store/auth/types'
import { ConfigurableTabBarNavigationInterface, TabBarSettingsInterface } from './types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

type Props = {
  orderedTabs: ConfigurableTabBarNavigationInterface[]
  name: TabBarNavigationNames
  isOpen: boolean
  onClose: () => void
  onSave: (settings: TabBarSettingsInterface | undefined) => void
}

export const SettingsSidebar = ({
  orderedTabs,
  name,
  isOpen,
  onClose,
  onSave,
}: Props) => {
  const [activeId, setActiveId] = useState<string | null>(null)
  const [unsavedSettings, setUnsavedSettings] = useState<TabBarSettingsInterface>({
    reordered: orderedTabs.map(tab => tab.key),
  })
  const activeTab = activeId && orderedTabs.find(tab => tab.key === activeId)

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      setUnsavedSettings(current => ({
        ...current,
        reordered:
          startIndex === endIndex
            ? current.reordered
            : move(current.reordered, startIndex, endIndex),
      }))
    }

    setActiveId(null)
  }, [])

  const handleSave = () => {
    workspaceLocalStorage.setItem(
      LocalStorageKeys.NAVIGATION_TABS_SETTINGS.replace('{}', name),
      JSON.stringify(unsavedSettings),
    )
    onSave(unsavedSettings)
    onClose()
  }

  const handleReset = () => {
    workspaceLocalStorage.removeItem(
      LocalStorageKeys.NAVIGATION_TABS_SETTINGS.replace('{}', name),
    )
    onSave(undefined)
    onClose()
  }

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title="Edit order"
      subtitle="Change the order of the tabs as you prefer"
      useIcon={Pencil}
      avatarProps={{ color: Token.color.greyTone20 }}
    >
      <Flex flexDirection="column" gap="s-16">
        <Box radius={Token.radius.widget} bg={Token.color.widgetBackground}>
          <VStack>
            <DragAndDrop.Provider
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragCancel={onDragCancel}
            >
              <DragAndDrop.Sortable
                id="sortable-table-preferences"
                items={unsavedSettings.reordered}
              >
                {sortable => {
                  const tab = orderedTabs.find(t => t.key === sortable.id)
                  return tab ? (
                    <Item
                      ref={sortable.setNodeRef}
                      style={{
                        transform: sortable.transform
                          ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                          : undefined,
                        transition: sortable.transition || 'none',
                        opacity: sortable.isDragging ? 0 : undefined,
                        backgroundColor: 'unset',
                      }}
                    >
                      {tab.icon && (
                        <Item.Prefix>
                          <Box color={Token.color.greyTone50}>{tab.icon}</Box>
                        </Item.Prefix>
                      )}
                      <Item.Content>
                        <Item.Title>{tab.title}</Item.Title>
                      </Item.Content>
                      <Item.Side>
                        <IconButton
                          aria-label="Drag"
                          useIcon={Drag}
                          color={Token.color.greyTone20}
                          {...sortable.attributes}
                          {...sortable.listeners}
                          size={16}
                        />
                      </Item.Side>
                    </Item>
                  ) : null
                }}
              </DragAndDrop.Sortable>
              <DragAndDrop.DragOverlay>
                {activeTab && (
                  <Box radius="cell" bg={Token.color.background}>
                    <Item>
                      {activeTab.icon && (
                        <Item.Prefix>
                          <Box color={Token.color.greyTone50}>{activeTab.icon}</Box>
                        </Item.Prefix>
                      )}
                      <Item.Content>
                        <Item.Title>{activeTab.title}</Item.Title>
                      </Item.Content>
                      <Item.Side>
                        <IconButton
                          aria-label="Drag"
                          useIcon={Drag}
                          color={Token.color.greyTone20}
                          size={16}
                        />
                      </Item.Side>
                    </Item>
                  </Box>
                )}
              </DragAndDrop.DragOverlay>
            </DragAndDrop.Provider>
          </VStack>
        </Box>
      </Flex>
      <Side.Actions>
        <Button onClick={handleReset} variant="secondary">
          Reset
        </Button>
        <Button elevated onClick={handleSave}>
          Done
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
